import React from "react";
import { Card, CardBody, Col, TabContent, TabPane } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import ExtendedTableContainer from "Components/Common/ExtendedTableContainer";

interface Props {
    userChatOpen: any;
    clients: any[];
    currentClient: any;
    clientsLoading: boolean;
    totalClients: number;
    currentPage: any;
    setCurrentPage: any;
}

const ChatList: React.FC<Props> = ({ clients, currentClient, userChatOpen, clientsLoading, totalClients, currentPage, setCurrentPage }) => {

    return (
        <React.Fragment>
            <div className="chat-leftsidebar me-lg-4">
                <div>
                    <div className="chat-leftsidebar-nav">
                        <TabContent activeTab="chat">
                            <TabPane tabId="chat">
                                <div>
                                    <Col lg={12}>
                                      <Card>
                                      {clientsLoading ? <Spinners setLoading={() => {}} /> :
                                        <CardBody>
                                          <ExtendedTableContainer
                                            columns={[{
                                              header: 'Clients',
                                              accessorKey: 'name',
                                              enableColumnFilter: true,
                                              enableSorting: true,
                                              cell: (info) => (
                                                <div className="d-flex">
                                                    <div className="align-self-center me-3">
                                                        <i className={`mdi mdi-circle font-size-10 ${info.row.original.id === currentClient.id ? "text-success" : ""}`} />
                                                    </div>
                                                    <div className="flex-grow-1 overflow-hidden">
                                                        <h5 className="text-truncate font-size-14 mb-1">
                                                            {info.getValue()}
                                                        </h5>
                                                    </div>
                                                </div>
                                              )
                                            }]}
                                            data={clients || []}
                                            isGlobalFilter={false}
                                            isPagination={true}
                                            isAddButton={false}
                                            isCustomPageSize={false}
                                            totalResults={totalClients}
                                            currentPage={currentPage}
                                            onPageChange={setCurrentPage}
                                            handleRowClick={(client) => {userChatOpen(client)}}
                                            SearchPlaceholder="Search clients..."
                                            pagination="pagination"
                                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                          />
                                        </CardBody>}
                                      </Card>
                                    </Col>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ChatList;