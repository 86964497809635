import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import FileUploader from 'Components/Common/FileUploader';
import { generalizeValue } from 'utils';

type ILocationModal = {
    data?: any;
    programs?: any[];
    isOpen: boolean;
    isEdit?: boolean;
    onSave?: Function;
    toggle: () => void;
};

const LocationModal: React.FC<ILocationModal> = ({ data, isOpen, programs = [], isEdit, onSave, toggle }) => {
    const [newData, setNewData] = useState<any>({});
    const [programId, setProgramId] = useState<number>();
    const [logoFile, setLogoFile] = useState<File | null>(null);
    const [logoPreview, setLogoPreview] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const handleSaveClick = async () => {
        setLoading(true);
        try {
            await handleSave();
        } catch (error) {
            console.error('Failed to save location:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSave = async () => {
        const saveData = {
            ...newData,
            settings: {
                ...newData.settings,
                enabledGettingStartedVideoIds: newData?.settings?.enabledGettingStartedVideoIds || [], // Ensure it exists
            },
        };

        if (saveData.settings?.classifications?.role_manager) {
            saveData.settings.classifications.role_manager = saveData.settings.classifications.role_manager.filter(
                (manager) => manager.title && manager.title.trim() !== ''
            );
        }

        if (logoFile) {
            saveData.logoFile = logoFile;
        }

        if (onSave) {
            await onSave(isEdit ? newData.programId : programId, saveData);
        }
    };


    const handleFieldInput = (e) => {
        const generalizedValue = generalizeValue(e.target.type, e.target.value);
        setNewData((state) => ({
            ...state,
            [e.target.id]: generalizedValue,
        }));
    };

    const handleAddRoleManager = () => {
        setNewData((state) => ({
            ...state,
            settings: {
                ...(state?.settings || {}),
                classifications: {
                    ...(state?.settings?.classifications || {}),
                    role_manager: [
                        ...(state?.settings?.classifications?.role_manager || []),
                        { title: '', canPush: false, canInviteClients: false },
                    ],
                },
            },
        }));
    };

    const handleUpdateRoleManager = (index: number, field: string, value: any) => {
        const updatedRoleManager = [...(newData?.settings?.classifications?.role_manager || [])];
        updatedRoleManager[index][field] = value;

        setNewData((state) => ({
            ...state,
            settings: {
                ...(state?.settings || {}),
                classifications: {
                    ...(state?.settings?.classifications || {}),
                    role_manager: updatedRoleManager,
                },
            },
        }));
    };

    const handleRemoveRoleManager = (index: number) => {
        const updatedRoleManager = [...(newData?.settings?.classifications?.role_manager || [])];
        updatedRoleManager.splice(index, 1);

        setNewData((state) => ({
            ...state,
            settings: {
                ...(state?.settings || {}),
                classifications: {
                    ...(state?.settings?.classifications || {}),
                    role_manager: updatedRoleManager,
                },
            },
        }));
    };

    useEffect(() => {
        if (isOpen) {
            if (isEdit) {
                setNewData({
                    ...data,
                    settings: {
                        ...data?.settings,
                        enabledGettingStartedVideoIds: data?.settings?.enabledGettingStartedVideoIds || [], // Ensure it exists
                    },
                });
                setLogoPreview(data?.settings?.logo || null);
                setLogoFile(null);
            } else {
                setNewData({
                    settings: {
                        enabledGettingStartedVideoIds: [], // Add default value
                        classifications: {
                            role_manager: [],
                        },
                    },
                });
                setLogoPreview(null);
                setLogoFile(null);
            }
        }
    }, [data, isEdit, isOpen]);

    useEffect(() => {
        if (programs.length) {
            setProgramId(programs[0].id);
        }
    }, [programs]);

    const handleUploadLocationFiles = (files: File[]) => {
        if (files.length > 0) {
            setLogoFile(files[0]);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <div className="modal-title mt-0 h5" id="inviteModalLabel">
                    {isEdit ? 'Update Location' : 'New Location'}
                </div>
            </ModalHeader>
            <ModalBody>
                <form>
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">Name</label>
                        <input
                            value={newData?.name || ''}
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter name"
                            onChange={handleFieldInput}
                        />
                    </div>
                    {!isEdit && (
                        <div className="mb-3">
                            <label htmlFor="programId" className="form-label">Program</label>
                            <select
                                className="form-select form-control"
                                value={programId}
                                onChange={(e) => setProgramId(Number(e.target.value))}
                            >
                                {programs.map((program) => (
                                    <option key={program.id} value={program.id}>
                                        {program.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}

                    <div className="mb-3">
                        <label className="form-label">Role Managers</label>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <span>Manage role managers for this location:</span>
                            <button
                                type="button"
                                className="btn btn-sm btn-primary"
                                onClick={handleAddRoleManager} // Button always enabled
                            >
                                Add Role Manager
                            </button>
                        </div>
                        {(newData?.settings?.classifications?.role_manager || []).length === 0 ? (
                            <p className="text-danger mt-2">
                                No role managers to display. Add a new role manager to start.
                            </p>
                        ) : (
                            newData.settings.classifications.role_manager.map((manager, index) => (
                                <div key={index} className="mb-2 border p-2 rounded">
                                    <div className="mb-2">
                                        <label className="form-label">Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={manager.title || ''}
                                            onChange={(e) =>
                                                handleUpdateRoleManager(index, 'title', e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={manager.canPush || false}
                                            onChange={(e) =>
                                                handleUpdateRoleManager(index, 'canPush', e.target.checked)
                                            }
                                        />
                                        <label className="form-check-label">Can Push Notifications</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={manager.canInviteClients || false}
                                            onChange={(e) =>
                                                handleUpdateRoleManager(index, 'canInviteClients', e.target.checked)
                                            }
                                        />
                                        <label className="form-check-label">Can Invite Clients</label>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn btn-sm btn-danger mt-2"
                                        onClick={() => handleRemoveRoleManager(index)}
                                    >
                                        Remove
                                    </button>
                                </div>
                            ))
                        )}
                        {/* Warning message when title is missing */}
                        {(newData?.settings?.classifications?.role_manager || []).some(
                            (manager) => !manager.title || manager.title.trim() === ''
                        ) && (
                            <p className="text-danger mt-2">
                                Each role manager must have a title before you can save.
                            </p>
                        )}
                    </div>


                    <div className="mb-3">
                        <label htmlFor="client_title" className="form-label">Title for Client</label>
                        <input
                            value={newData?.settings?.client_title || ''}
                            type="text"
                            className="form-control"
                            id="client_title"
                            placeholder="Enter title for client"
                            onChange={(e) => {
                                setNewData((state) => ({
                                    ...state,
                                    settings: {
                                        ...(state?.settings || {}),
                                        client_title: e.target.value,
                                    },
                                }));
                            }}
                        />
                    </div>

                    <div className="form-check mb-3">
                        <input
                            checked={newData?.settings?.chatbot_enabled || false}
                            className="form-check-input"
                            type="checkbox"
                            id="chatbot_enabled"
                            onChange={() => {
                                setNewData((state) => ({
                                    ...state,
                                    settings: {
                                        ...(state?.settings || {}),
                                        chatbot_enabled: !state?.settings?.chatbot_enabled,
                                    },
                                }));
                            }}
                        />
                        <label className="form-check-label" htmlFor="chatbot_enabled">
                            Enable Chatbot for Location
                        </label>
                    </div>

                    {/* Logo Upload */}
                    <>
                        {logoPreview && (
                            <div className="mb-3">
                                <label>Current Logo</label>
                                <div className="image-preview mt-3">
                                    <img
                                        src={logoPreview}
                                        alt="Logo Preview"
                                        style={{maxWidth: '100%', maxHeight: '200px'}}
                                    />
                                </div>
                            </div>
                        )}

                        <FileUploader
                            label="Upload Logo File"
                            hint="Please upload a logo file related to the location."
                            multiple={false}
                            onSend={handleUploadLocationFiles}
                            onChange={handleUploadLocationFiles}
                        />
                    </>
                </form>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary"
                >
                    Close
                </button>
                <button

                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveClick}
                    disabled={
                        loading ||
                        !(newData?.settings?.classifications?.role_manager || []).some(
                            (manager) => manager.title && manager.title.trim() !== ''
                        )
                    }

                >
                    {loading ? 'Saving...' : isEdit ? 'Update Location' : 'Create Location'}
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default LocationModal;
