import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import ChatList from "../../../slices/chat/ChatList";
import UserChat from "../../../slices/chat/ChatWindow";
import { toast } from 'react-toastify';

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";

//redux
import { getClientChatHistory, getClients } from "../../../helpers/backend_helper";
import { userManager } from "utils/UserManager";

interface FilteredChatHistories {
  likes: any[],
  dislikes: any[],
  interventions: any[]
}

const ChatHistoryPage: React.FC<{}> = () => {
  const [clientsLoading, setClientsLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<any[]>([]);
  const [currentClient, setCurrentClient] = useState<any>({});
  const [chatHistoryLoading, setChatHistoryLoading] = useState<boolean>(false);
  const [chatHistory, setChatHistory] = useState<any[]>([]);
  const [filteredChats, setFilteredChats] = useState<FilteredChatHistories>({
    likes: [],
    dislikes: [],
    interventions: []
  });

  // pagination properties
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [totalResults, setTotalResults] = useState<number>(0);

  //meta title
  document.title = `Chat | Chat History`;

  const currentUser = userManager;

  const fetchClients = async (currentPage) => {
    try {
        // Ensure currentUser and dbUser are defined and have a groupIds array
        const user = currentUser.getUser();
        const userGroupId = user?.dbUser?.groupIds?.[0];

        const response = await getClients(1, currentPage * 10, 10, userGroupId); // Add parameters if needed
        if (response.success) {

            setClients(response.data.clients);
            setTotalResults(response.data.totalResults ?? response.data.clients.length);
            const firstClient = response.data.clients[0];
            if (firstClient) {
              setCurrentClient(firstClient);
            }
        } else {
            // Display error notification
            toast.error(response.error || "Failed to fetch clients.");
        }
    } catch (error) {
        toast.error("Failed to fetch clients.");
    }
    setClientsLoading(false);
  };

  const fetchChatHistory = async () => {
    try {
      const currentClientId = currentClient?.id;
      if (currentClientId) {
        const response = await getClientChatHistory(currentClientId);
        const newChatHistory = response.chat_history;
        if (newChatHistory) {
          setChatHistory(newChatHistory);
          parseFilteredChats(newChatHistory);
        } else {
          toast.error(response.error || "Failed to fetch client chat history");
        }
      }
    } catch (err) {
      toast.error("Failed to fetch client chat history");
    }
    setChatHistoryLoading(false);
  };

  const parseFilteredChats = (chatHistory: any[]) => {
    const likeSnippets: any[] = [];
    const dislikeSnippets: any[] = [];
    const interventionSnippets: any[] = [];

    for (let i=0; i<chatHistory.length; i++) {
      if(chatHistory[i]?.metadata?.guardrail_interventions) {
        if (chatHistory[i-1]) {
          interventionSnippets.push(chatHistory[i-1])
        }
        interventionSnippets.push(chatHistory[i]);
        if (chatHistory[i+1]) {
          interventionSnippets.push(chatHistory[i+1]);
        }
      }
      if (chatHistory[i+1]?.metadata?.liked_at) {
        if (chatHistory[i]) {
          likeSnippets.push(chatHistory[i]);
        }
        likeSnippets.push(chatHistory[i+1]);
      }
      if (chatHistory[i+1]?.metadata?.disliked_at) {
        if (chatHistory[i]) {
          dislikeSnippets.push(chatHistory[i]);
        }
        dislikeSnippets.push(chatHistory[i+1]);
      }
    }
    const filteredHistories: FilteredChatHistories = {
      likes: likeSnippets,
      dislikes: dislikeSnippets,
      interventions: interventionSnippets
    };
    setFilteredChats(filteredHistories);
  };

  useEffect(() => {
    setClientsLoading(true);
    setChatHistoryLoading(true);
    fetchClients(currentPage);
  }, [currentPage]);
  useEffect(() => {
    fetchChatHistory();
  }, [currentClient]);

  const userChatOpen = (client: any) => {
    setChatHistory([]);
    setChatHistoryLoading(true);
    setCurrentClient(client);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Chat History" breadcrumbItem="Chat" />

          <div className="d-lg-flex">

            <ChatList
              userChatOpen={userChatOpen}
              currentClient={currentClient}
              clients={clients}
              clientsLoading={clientsLoading}
              totalClients={totalResults}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />

            <UserChat
              Chat_Box_Username={currentClient?.name ?? "Chat History"}
              messages={chatHistory}
              filteredMessages={filteredChats}
              loading={chatHistoryLoading}
              chatType="chatbotHistory"
              client={currentClient}
            />

          </div>
        </Container>
      </div >
    </React.Fragment >
  );
};



export default ChatHistoryPage;
