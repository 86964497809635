import React, {useEffect, useMemo, useState} from "react";
import { Link, useNavigate } from "react-router-dom";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {
    addProgramTenant,
    getPrograms,
    getTenants,
    updateProgramTenant,
    updateProgramTenantWithLogo, uploadFiles, uploadImageOnS3
} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import { convertUTC2Local } from "utils";
import LocationModal from "Components/Modal/LocationModal";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const LocationsPage = ({title}) => {
    const navigate = useNavigate();
    const [locationsData, setLocationsData] = useState<any>([]);
    const [programsData, setProgramsData] = useState<any[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateLocation = async (programId: number, newLocation: any) => {

            try {
                let logoFile;
                if (newLocation.logoFile)
                {
                    logoFile = newLocation.logoFile;
                    delete newLocation.logoFile;

                }

                const response = await addProgramTenant(programId, newLocation); // Add parameters if needed
                if (response.success) {


                    console.log(newLocation, logoFile);

                    const tenant = response.data;
                    setLocationsData((state) => [...state, tenant]);
                    toast.success("Created new location.",tenant);

                    if (logoFile)
                    {
                        // Assuming originalLocation.logoFile['name'] contains the file name, e.g., "example.png"
                        const originalFileName = logoFile['name'];
                        // Extract the file extension
                        const fileExtension = originalFileName.split('.').pop();
                        // Create the new file name with "logo" as the base name
                        const newFileName = `logo.${fileExtension}`;
                        // request signed upload url
                        const uploads = [
                            {
                                resourcePath: `settings/logo/${programId}`,
                                fileName: newFileName
                            }
                        ]
                        const response = await uploadFiles(uploads,tenant.dbName);
                        if (response.success) {
                            const putUrl = response.data.uploads?.[0]?.putUrl
                            // generate the url and update the associated record
                            await uploadImageOnS3(putUrl, logoFile)
                            const tntResponse = await getTenants(0, 50); // Add parameters if needed
                            if (tntResponse.success) {
                                const tenants = tntResponse.data.tenants;
                                setLocationsData(tenants);
                            }
                        }

                    }

                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create location.");
                }
            } catch (error) {

                toast.error("Failed to create location.");
            }
    }
    function prepareLocationData(locationData: any) {
        const { lastModifiedBy, createdAt, updatedAt, deletedAt, programId, dbName, id, ...cleanData } = locationData;
        return cleanData;
    }
    const handleEditLocation = async (programId: number, originalLocation: any) => {

            try {
                if (originalLocation.logoFile)
                {
                    // Assuming originalLocation.logoFile['name'] contains the file name, e.g., "example.png"
                    const originalFileName = originalLocation.logoFile['name'];
                    // Extract the file extension
                    const fileExtension = originalFileName.split('.').pop();
                    // Create the new file name with "logo" as the base name
                    const newFileName = `logo.${fileExtension}`;
                        // request signed upload url
                        const uploads = [
                            {
                                resourcePath: `settings/logo/${programId}`,
                                fileName: newFileName
                            }
                        ]
                        const response = await uploadFiles(uploads,valueToBeModified.dbName);
                        if (response.success) {
                            const putUrl = response.data.uploads?.[0]?.putUrl
                            // generate the url and update the associated record
                            await uploadImageOnS3(putUrl, originalLocation.logoFile)
                            const tntResponse = await getTenants(0, 50); // Add parameters if needed
                            if (tntResponse.success) {
                                const tenants = tntResponse.data.tenants;
                                setLocationsData(tenants);
                                // Find the matching location and update originalLocation
                                const matchingLocation = tenants.find(location => {
                                    return location.id === originalLocation.id;
                                });
                                // Find the matching location and update originalLocation
                                if (matchingLocation) {
                                    // Assuming you want to add the logoUri to originalLocation
                                    originalLocation.settings.logoUri = matchingLocation.settings.logoUri; // or whatever key holds the logo URL
                                }
                            }
                        }
                }

                    const response = await updateProgramTenantWithLogo(programId, originalLocation.id, originalLocation);

                    if (response.success) {
                        setLocationsData((state) => {
                            const findIdx = state.findIndex(item => item?.id === originalLocation.id);
                            if (!isNaN(findIdx)) {
                                state[findIdx] = originalLocation;
                            }
                            return [...state];
                        });
                        toggleEditModal();
                    } else {
                        toast.error(response.error || "Failed to update location.");
                    }



            } catch (error) {
                toast.error("Failed to update location.");
            }

    };

    const fetchLocations = async () => {
        try {
            const response = await getTenants(0, 50); // Add parameters if needed
            if (response.success) {

                setLocationsData(response.data.tenants);

                return response;
            } else {
                // Display error notification
                toast.error(response.error || "Failed to fetch tenants.");
                return null;
            }
        } catch (error) {

            toast.error("Failed to fetch tenants.");
            return null;
        }
    };
    const fetchPrograms = async () => {
        try {
            const response = await getPrograms(1, 0, 50); // Add parameters if needed
            if (response.success) {

                setProgramsData(response.data.programs);
            } else {
                // Display error notification
                toast.error(response.error || "Failed to fetch programs.");
            }
        } catch (error) {

            toast.error("Failed to fetch programs.");
        }
    };
    useEffect(() => {
        fetchPrograms();
        fetchLocations();
    }, []);
    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Program Type',
                accessorKey: 'programId',
                cell: (info) => {
                    const programId = info.getValue();
                    const program = programsData.find(program => program.id === programId)
                    return program?.name ?? ''
                },
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-success" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleEditModal()
                            }}>
                            <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                            <UncontrolledTooltip placement="top" target="editTooltip">
                                Edit
                            </UncontrolledTooltip>
                            </Link>
                            </div>
                    )
                }
            },
            // Add more columns as needed
        ],
        [toggleNewModal]
    );

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={locationsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={true}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search locations..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Location"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <LocationModal isOpen={newModalIsOpen} programs={programsData} toggle={toggleNewModal} onSave={handleCreateLocation} />
                            <LocationModal isOpen={editModalIsOpen} programs={programsData} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditLocation} />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default LocationsPage;
