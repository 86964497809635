import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { generalizeValue } from 'utils';
import { UserRoleKeys } from 'utils/UserManager';
import { toast, ToastContainer } from 'react-toastify';

const InviteModal = ({ 
  showManagersDropdown = false,
  showTenantsDropdown = false,
  // enable/disable phone number field to capture for invite
  retrievePhone = false,
  // allow for email/phone number invite method(s) to be toggled
  enableInviteToggle = false,
  // flag to distinguish invite creation from a resend
  isResend = false,
  managers = [] as any[],
  tenants = [] as any[],
  isOpen,
  toggle,
  onSave }) => {
    const initialData = {
      ...(enableInviteToggle ? {
        sendText: true,
        sendEmail: true
      } : {})
    }
    const [newData, setNewData] = useState<any>(initialData);
    const [managerId, setManagerId] = useState<number>();
    const [tenantId, setTenantId] = useState<number>();

    const handleSave = () => {
        if (enableInviteToggle && !(newData.sendText || newData.sendEmail)) {
          toast.error("At least 1 invite method must be selected");
        }
        else if (showManagersDropdown) {
            onSave && onSave(newData, managerId)
        } else if (showTenantsDropdown) {
            onSave && onSave(newData, tenantId)
        } else {
            onSave && onSave(newData)
        }
    }

    const handleFieldInput = (e) => {
        const generalizedValue = generalizeValue(e.target.type, e.target.value)
        setNewData((state) => ({
            ...state,
            [e.target.id]: generalizedValue
        }))
    }

    const handlePhoneInput = (e) => {
      const generalizedValue = generalizeValue(e.target.type, e.target.value)

      if (/^\d{0,10}$/.test(generalizedValue)) {
        setNewData((state) => ({
          ...state,
          [e.target.id]: generalizedValue
      }))
      }
  }

    useEffect(() => {
        setManagerId(undefined);
        setTenantId(undefined);
        setNewData(initialData)
    }, [isOpen]);

    // Group managers by classification or another property
    const groupedManagers: Record<string, { id: number; name: string }[]> = managers.reduce(
        (groups, manager) => {
            const classification = manager.classification || 'Unclassified';
            if (!groups[classification]) {
                groups[classification] = [];
            }
            groups[classification].push(manager);
            return groups;
        },
        {} as Record<string, { id: number; name: string }[]>
    );



    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ToastContainer />
            <ModalHeader toggle={toggle}>
                <div className="modal-title mt-0 h5" id="inviteModalLabel">
                    {isResend ? "Resend Invite" : "Invite Someone"}
                </div>
            </ModalHeader>
            <ModalBody>
                <form>
                    {!isResend && <div className="mb-3">
                        <label htmlFor="nameInvited" className="form-label">Name</label>
                        <input value={newData?.nameInvited ?? ''} type="text" className="form-control" id="nameInvited" placeholder="Enter name" onChange={handleFieldInput} />
                    </div>}
                    {!isResend && <div className="mb-3">
                        <label htmlFor="emailInvited" className="form-label">Email address</label>
                        <input value={newData?.emailInvited ?? ''} type="email" className="form-control" id="emailInvited" placeholder="Enter email" onChange={handleFieldInput} />
                    </div>}
                    {!isResend && retrievePhone && <div className="mb-3">
                        <label htmlFor="emailInvited" className="form-label">Phone Number</label>
                        <input value={newData?.phoneNumberInvited ?? ''} type="text" className="form-control" id="phoneNumberInvited" placeholder="Enter phone number" onChange={handlePhoneInput} />
                    </div>}
                    {showManagersDropdown && (
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                Managers
                            </label>
                            <select
                                className="form-select form-control"
                                value={managerId ?? 'Select'}
                                onChange={(e) => {
                                    setManagerId(Number(e.target.value));
                                }}
                            >
                                <option>Select</option>
                                {Object.entries(groupedManagers).map(([classification, managers]) => (
                                    <optgroup key={classification} label={classification}>
                                        {managers.map(manager => (
                                            <option key={manager.id} value={manager.id}>
                                                {manager.name}
                                            </option>
                                        ))}
                                    </optgroup>
                                ))}
                            </select>
                        </div>
                    )}
                    {showTenantsDropdown && (
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Locations</label>
                            <select className="form-select form-control" value={tenantId ?? 'Select'} onChange={(e) => {
                                setTenantId(Number(e.target.value))
                            }}>
                            <option>Select</option>
                            {tenants?.map(tenant => (
                                <option key={tenant.id} value={tenant.id}>{tenant.name}</option>
                            ))}
                            </select>
                        </div>
                    )}
                    {enableInviteToggle &&
                    <>
                      <label htmlFor="nameInvited" className="form-label">Toggle Invitation Methods</label>
                      <div className="form-check mb-3">
                        <label htmlFor="nameInvited" className="form-check-label">Text</label>
                        <input checked={newData?.sendText ?? false} value={newData?.sendText ?? false} type="checkbox" className="form-check-input" id="sendText" onChange={() => {setNewData({...newData, sendText: !newData.sendText})}} />
                      </div>
                      <div className="form-check mb-3">
                        <label htmlFor="nameInvited" className="form-check-label">Email</label>
                        <input checked={newData?.sendEmail ?? false} value={newData?.sendEmail ?? false} type="checkbox" className="form-check-input" id="sendEmail" onChange={() => {setNewData({...newData, sendEmail: !newData.sendEmail})}} />
                      </div>
                    </>}
                    <p>Please fill out the form to send an invitation.</p>
                </form>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                >
                    Send Invitation
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default InviteModal;
