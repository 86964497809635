import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Card, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import { assumeRole, getMe, getTenants, revertAssumedRole } from 'helpers/backend_helper';
import { userManager } from 'utils/UserManager';
import { useNavigate } from 'react-router-dom';
import Spinners from "Components/Common/Spinner";
import ExtendedTableContainer from 'Components/Common/ExtendedTableContainer';

type IChangeLocationModal = {
  isOpen: boolean;
  toggle: () => void
}

const ChangeLocationModal: React.FC<IChangeLocationModal> = ({ isOpen, toggle }) => {
  const [locations, setLocations] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  // pagination properties
  const [totalResults, setTotalResults] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);

  const fetchLocations = async (currentPage) => {
    try {
      const response = await getTenants(currentPage * 10, 10);
      if (response.success) {
        setLocations(response.data.tenants);
        setTotalResults(response.data.totalResults ?? response.data.tenants.length);
      } else {
        toast.error(response.error || "Failed to get locations.");
      }
    } catch (error) {
      toast.error("Failed to get locations.")
    }
  };

  useEffect(() => {
    if (userManager.isAssumedCorporate()) {
      fetchLocations(currentPage);
    }
  }, [currentPage]);

  const handleReload = async () => {
    const me = (await getMe()).data;
    if (me) {
      userManager.setUserData(me, {quiet: true});
      console.log(me);
    }
    toggle();
    navigate("/");
    window.location.reload();
  };

  const handleSave = async (tenantRecord:any) => {
    if (!loading) {
      setLoading(true);
      const { dbName: tenantId } = tenantRecord;
      const role = "role_admin";

      await assumeRole(tenantId, role);
      await handleReload();
      setLoading(false);
    }
  }

  const handleRevertToCorp = async () => {
    if (!loading) {
      setLoading(true);
      const roleAssumptionId = userManager.getUser()?.tenantAssumptionId;

      if (roleAssumptionId) {
        await revertAssumedRole(roleAssumptionId);
      }
      await handleReload();
      setLoading(false);
    }
  };

  return (
      <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                  {loading ? "Changing Location..." : "Select a Location"}
              </div>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                  <ExtendedTableContainer
                      columns={[{
                        header: "Select a Location to Enter",
                        accessorKey: "name",
                        enableColumnFilter: false,
                        enableSorting: true,
                        cell: (info) => <a>{info.getValue()}</a>
                      }]}
                      data={locations || []}
                      isGlobalFilter={false}
                      isPagination={true}
                      isAddButton={false}
                      isCustomPageSize={false}
                      totalResults={totalResults}
                      currentPage={currentPage}
                      onPageChange={setCurrentPage}
                      handleUserClick={(e) => {console.log(e)}}
                      handleRowClick={handleSave}
                      SearchPlaceholder="Search locations..."
                      pagination="pagination"
                      paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"

                      tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                      theadClass="table-light"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggle}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  onClick={handleRevertToCorp}
                  className="btn btn-primary"
                  data-dismiss="modal"
              >
                  Return to Corporate
              </button>
          </ModalFooter>
          {loading && <Spinners setLoading={() => {}}/>}
      </Modal>
  );
};

export default ChangeLocationModal;
