import React, {useEffect, useMemo, useState} from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {deleteAdmin, getAdmins, getTenants, sendNewInvitation, updateAdmin} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import InviteModal from "Components/Modal/InviteModal";
import { UserRoleKeys, userManager } from "utils/UserManager";
import { convertUTC2Local } from "utils";
import { Link } from "react-router-dom";
import DeleteModal from "Components/Modal/DeleteModal";
import AdminModal from "Components/Modal/AdminModal";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}


const AdminsPage = ({title}) => {
    const [adminsData, setAdminsData] = useState<any[]>([]);
    const [tenantFilterId, setTenantFilterId] = useState<string>();
    const [tenantsData, setTenantsData] = useState<any[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const userRole = userManager.getUserRole();

    const [adminCurrentPage, setAdminCurrentPage] = useState(0);
    const [adminPageSize] = useState(50);
    const [tenantCurrentPage, setTenantCurrentPage] = useState(0);
    const [tenantPageSize] = useState(20);
    const [totalResults, setTotalResults] = useState(0);


    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(state => !state);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(state => !state);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateInvite = (newInvite: any, tenantId: number) => {
        let dbName: string;
        if (userRole === UserRoleKeys.RoleCorp) {
            const tenant = tenantsData.find(tenant => tenant.id === tenantId);
            dbName = tenant.dbName;
        }

        const createInvite = async () => {
            try {
                const response = await sendNewInvitation(UserRoleKeys.RoleAdmin, newInvite, dbName); // Add parameters if needed
                if (response.success) {
                    toast.success("New invitation sent.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    console.log(response);
                    toast.error(response.error || "Failed to send new invite.");
                }
            } catch (error: any) {

                // Check if the error message matches the condition for an existing pending invitation
                if (error.message === "failed to create auth user") {
                    toast.error("There is an existing invitation pending for this email.");
                } else {
                    toast.error("Failed to send new invite.");
                }
            }
        };

        createInvite();
    }

    const handleEditAdmin = (admin: any) => {
        const modifyAdmin = async () => {
            try {
                const payload = {
                    id: admin.id,
                    name: admin.name,
                    phoneNumber: admin.phoneNumber
                }
                const response = await updateAdmin(payload, tenantFilterId ?? ''); // Add parameters if needed
                if (response.success) {

                    setAdminsData((state) => {
                        const findIdx = state.findIndex(item => item?.id === admin.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = response.data
                        }

                        return [...state]
                    });
                    toggleEditModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update admin.");
                }
            } catch (error) {

                toast.error("Failed to update admin.");
            }
        };

        modifyAdmin();
    }

    const handleDeleteAdmin = () => {
        const removeAdmin = async () => {
            try {
                const response = await deleteAdmin(valueToBeModified.id, tenantFilterId ?? ''); // Add parameters if needed
                if (response.success) {

                    setAdminsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);

                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove admin.");
                }
            } catch (error) {

                toast.error("Failed to remove admin.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeAdmin()
        }
    }

    // Fetch tenants
    useEffect(() => {
        const fetchTenants = async () => {
            setLoading(true);
            try {
                const offset = tenantCurrentPage * tenantPageSize;
                const response = await getTenants(offset, tenantPageSize);
                if (response.success) {
                    setTenantsData(response.data.tenants);
                    setTenantFilterId(response.data.tenants[0]?.dbName);
                    setTotalResults(response.data.totalResults);
                } else {
                    toast.error(response.error || "Failed to fetch tenants.");
                }
            } catch (error) {
                toast.error("Failed to fetch tenants.");
            } finally {
                setLoading(false);
            }
        };

        fetchTenants();
    }, [tenantCurrentPage, tenantPageSize]);




    // Fetch admins
    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const offset = adminCurrentPage * adminPageSize;
                console.log(offset,adminCurrentPage,adminPageSize);

                const response = await getAdmins(tenantFilterId, 1, offset, adminPageSize);
                if (response.success) {
                    setAdminsData(response.data.admins);
                } else {
                    toast.error(response.error || "Failed to fetch admins.");
                }
            } catch (error) {
                toast.error("Failed to fetch admins.");
            }
        };

        if (tenantFilterId) {
            fetchAdmins();
        }
    }, [tenantFilterId, adminCurrentPage, adminPageSize]);


    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Email',
                accessorKey: 'email',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Role',
                accessorKey: 'role',
                cell: (info) => <RoleCell value={info.getValue()} />,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Phone Number',
                accessorKey: 'phoneNumber',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-success" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleEditModal()
                            }}>
                            <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                            <UncontrolledTooltip placement="top" target="editTooltip">
                                Edit
                            </UncontrolledTooltip>
                            </Link>
                            <Link to="#" className="text-danger" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleDeleteModal()
                            }}>
                            <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                            <UncontrolledTooltip placement="top" target="deleteTooltip">
                                Delete
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            }
            // Add more columns as needed
        ],
        []
    );

    const customLeftTools = useMemo(() => {
        if (userRole === UserRoleKeys.RoleCorp) {
            return (
                <div className="d-flex gap-3 align-items-center">
                    <div>
                        <select
                            className="form-select form-control"
                            value={tenantFilterId ?? "Select"}
                            onChange={(e) => {
                                setTenantFilterId(e.target.value);
                                setAdminCurrentPage(0); // Reset admin pagination when tenant changes
                            }}
                        >
                            <option>Select</option>
                            {tenantsData?.map((tenant) => (
                                <option key={tenant.id} value={tenant.dbName}>
                                    {tenant.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <select
                            className="form-select form-control"
                            value={tenantCurrentPage}
                            onChange={(e) => setTenantCurrentPage(Number(e.target.value))}
                        >
                            {Array.from(
                                { length: Math.ceil(totalResults / tenantPageSize) },
                                (_, i) => (
                                    <option key={i} value={i}>
                                        Page {i}
                                    </option>
                                )
                            )}
                        </select>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <select
                        className="form-select form-control"
                        value={adminCurrentPage}
                        onChange={(e) => setAdminCurrentPage(Number(e.target.value))}
                    >
                        {Array.from(
                            { length: Math.ceil(totalResults / adminPageSize) },
                            (_, i) => (
                                <option key={i} value={i}>
                                    Page {i}
                                </option>
                            )
                        )}
                    </select>
                </div>
            );
        }
    }, [
        tenantFilterId,
        tenantsData,
        userRole,
        tenantCurrentPage,
        totalResults,
        tenantPageSize,
        adminCurrentPage,
        adminPageSize
    ]);


    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={adminsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={true}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search admins..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Admin"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    customLeftTools={
                                                        customLeftTools
                                                    }
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <InviteModal showTenantsDropdown={userRole === UserRoleKeys.RoleCorp} tenants={tenantsData} isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateInvite} />
                            <AdminModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditAdmin} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this admin? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteAdmin}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default AdminsPage;
