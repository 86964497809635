import React, { useEffect, useRef, useCallback } from "react";
//Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";

import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import withRouter from "../../Components/Common/withRouter";
import {userManager} from "../../utils/UserManager";

const SidebarContent = (props: any) => {
  const ref = useRef<any>();
  const activateParentDropdown = useCallback((item: any) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const activeMenu = useCallback(() => {
    const pathName = process.env.PUBLIC_URL + props.router.location.pathname;
    let matchingMenuItem = null;
    const ul: any = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName.startsWith(items[i].pathname)) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.router.location.pathname, activateParentDropdown]);
  const currentUser = userManager;
  const tenantSetting = userManager.getUser()?.tenantDetails?.settings;

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item: any) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }
  const classification = userManager.getUser()?.dbUser?.['classification']?.toLowerCase();
  const roleManagers = tenantSetting?.['classifications']?.['role_manager'] || [];

  const canAccessNotifications =
      currentUser.isCorporate() || // Always allow for corporate users
      currentUser.isAdmin() || // Always allow for corporate users
      roleManagers.some(
          (role) =>
              role.title.toLowerCase() === classification && role.canPush
      );


  const clientTitle = (!currentUser.isCorporate() && tenantSetting?.['client_title']) ? tenantSetting?.['client_title'] : 'Clients';
  const coachTitle = (!currentUser.isCorporate() && tenantSetting?.['use_coach_name']) ? tenantSetting?.['use_coach_name'] : 'Managers';



 console.log(roleManagers);
  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t("User Management")} </li>
            {currentUser.isCorporate() &&
                <li>
                  <Link to="/corporate">
                    <i className="bx bxs-building"></i>
                    <span>{props.t("Corporate")}</span>
                  </Link>
                </li>}
            {currentUser.isCorporate() &&
                <li>
                  <Link to="/admins">
                    <i className="bx bxs-store"></i>
                    <span>{props.t("Administrators")}</span>
                  </Link>
                </li>}

            {currentUser.isAdmin() && (
                <React.Fragment>
                  {roleManagers.length > 0 ? (
                      roleManagers.map((role: any, index: number) => {
                        const urlSafeTitle = role.title.toLowerCase().replace(/\s+/g, "-"); // Replace spaces with hyphens
                        return (
                            <li key={index}>
                              <Link to={`/managers/${encodeURIComponent(urlSafeTitle)}`} state={{ classification: role.title }}>
                                <i className="bx bxs-briefcase"></i>
                                <span>{props.t(role.title)}</span>
                              </Link>
                            </li>
                        );
                      })
                  ) : (
                      <p className="text-muted px-3">
                        No roles available. Please contact your administrator.
                      </p>
                  )}
                </React.Fragment>
            )}



            {(currentUser.isAdmin() || currentUser.isManager()) &&  <li>
              <Link to="/clients">
                <i className="bx bxs-group"></i>
                <span>{props.t(clientTitle)}</span>
              </Link>
            </li>}
            {(currentUser.isAdmin() || currentUser.isCorporate() || currentUser.isManager()) &&
                <li>
              <Link to="/invitations">
                <i className="bx bxs-envelope"></i>
                <span>{props.t("Invitations")}</span>
              </Link>
            </li>}
            { currentUser.isCorporate() && <li className="menu-title">{props.t("Location Management")} </li>}
            {currentUser.isCorporate() && <li>
              <Link to="/locations">
                <i className="bx bxs-building-house"></i>
                <span>{props.t("Locations")}</span>
              </Link>
            </li>}
            {currentUser.isCorporate() && <li>
              <Link to="/programs">
                <i className="bx bxs-data"></i>
                <span>{props.t("Program Management")}</span>
              </Link>
            </li>}
            { (currentUser.isAdmin() || currentUser.isCorporate() || currentUser.isManager()) && <li className="menu-title">{props.t("Content Management")} </li>}
            { (currentUser.isAdmin() || currentUser.isCorporate() || currentUser.isManager()) && <li>
              <Link to="/resources">
                <i className="bx bxs-album"></i>
                <span>{props.t("Resources")}</span>
              </Link>
            </li>}
            { (currentUser.isAdmin() || currentUser.isCorporate() ) &&   <li>
              <Link to="/goals">
                <i className="bx bxs-upvote"></i>
                <span>{props.t("Program Goals")}</span>
              </Link>
            </li>}
            {currentUser.isCorporate() && <li>
              <Link to="/quotes">
                <i className="bx bx-text"></i>
                <span>{props.t("Daily Quotes")}</span>
              </Link>
            </li>}
            {(currentUser.isCorporate() || currentUser.isAdmin()) && <li>
              <Link to="/videos">
                <i className="bx bxs-videos"></i>
                <span>{props.t("Gettings Started")}</span>
              </Link>
            </li>}
            {(currentUser.isAdmin() || currentUser.isManager()) && <li className="menu-title">{props.t(`${clientTitle} Management`)} </li> }
            {(currentUser.isAdmin() || currentUser.isManager()) &&<li>
              <Link to="/calendar">
                <i className="bx bx-calendar"></i>
                <span>{props.t("Calendar")}</span>
              </Link>
            </li>}
            { (currentUser.isAdmin() || currentUser.isManager()) &&
                <li className="menu-title">{props.t("Rewards Management")} </li>}
            { (currentUser.isAdmin() || currentUser.isManager()) &&  <li>
              <Link to="/standings">
                <i className="bx bxs-star"></i>
                <span>{props.t("Standings")}</span>
              </Link>
            </li>}
            { currentUser.isAdmin() &&  <li>
              <Link to="/rules">
                <i className="bx bxs-ruler"></i>
                <span>{props.t("Rules")}</span>
              </Link>
            </li>}
            { currentUser.isAdmin() &&   <li>
              <Link to="/rulegroups">
                <i className="bx bxs-grid"></i>
                <span>{props.t("Groups")}</span>
              </Link>
            </li>}
            { currentUser.isAdmin() &&  <li>
              <Link to="/campaigns">
                <i className="bx bxs-badge"></i>
                <span>{props.t("Campaigns")}</span>
              </Link>
            </li>}
            { (currentUser.isAdmin() || currentUser.isCorporate() || currentUser.isManager()) &&  <li className="menu-title">{props.t("Notification Management")} </li>}
            { (currentUser.isAdmin() || currentUser.isCorporate() || currentUser.isManager()) &&  <li>
              <Link to="/announcements">
                <i className="bx bxs-notepad"></i>
                <span>{props.t("Announcements")}</span>
              </Link>
            </li>}

            {canAccessNotifications && (
                <li>
                  <Link to="/notifications">
                    <i className="bx bxs-notification"></i>
                    <span>{props.t("Notifications")}</span>
                  </Link>
                </li>
            )}
            {canAccessNotifications && (
                <li>
                  <Link to="/send-notification">
                    <i className="bx bxs-bell-plus"></i>
                    <span>{props.t("Send Notifications")}</span>
                  </Link>
                </li>
            )}

            { (currentUser.isAdmin() || currentUser.isManager()) &&  <li className="menu-title">{props.t("Reports")} </li> }
            { (currentUser.isAdmin() || currentUser.isManager()) &&  <li>
              <Link to="/reports/case">
                <i className="bx bxs-report"></i>
                <span>{props.t(`${clientTitle} Reports`)}</span>
              </Link>
            </li>}
            { currentUser.isManager() &&  <li>
              <Link to="/reports/goals">
                <i className="bx bxs-report"></i>
                <span>{props.t(`${clientTitle} Goals`)}</span>
              </Link>
            </li>}
            {
              // currently limiting the knowledge base page & functionality to the development environment
            }
            { (currentUser.isCorporate() || (currentUser.isManager() && currentUser.isChatbotEnabled())) && process.env.REACT_APP_ENV_SETTING === "production" &&
            <li className="menu-title">{props.t("Chatbot Management")} </li>}
            { currentUser.isCorporate() && process.env.REACT_APP_ENV_SETTING === "production" &&
            <>
              <li>
                <Link to="/knowledge-bases">
                  <i className="bx bxs-bot"></i>
                  <span>{props.t("Knowledge Base")}</span>
                </Link>
              </li><li>
                <Link to="/guardrails">
                  <i className="bx bxs-shield"></i>
                  <span>{props.t("Guardrail")}</span>
                </Link>
              </li>
            </>}
            {
              currentUser.isManager() && currentUser.isChatbotEnabled() && process.env.REACT_APP_ENV_SETTING === "production" &&
              <li>
                <Link to="/chat-history">
                  <i className="bx bxs-conversation"></i>
                  <span>{props.t("Chat History")}</span>
                </Link>
              </li>
            }
            {(currentUser.isChatbotEnabled() || currentUser.isCorporate()) && process.env.REACT_APP_ENV_SETTING === "production" && <li className="menu-title">{props.t("Support")} </li>}
            {(currentUser.isChatbotEnabled() || currentUser.isCorporate()) && process.env.REACT_APP_ENV_SETTING === "production" && <li>
              <Link to="/chat">
                <i className="bx bxs-message"></i>
                <span>{props.t("Chat")}</span>
              </Link>
            </li>}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};
export default withRouter(withTranslation()(SidebarContent));
