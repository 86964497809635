export function convertUTC2Local(utcDateStr: string | null): string {
  if (!utcDateStr) {
    return ''; // Handle null or undefined inputs
  }

  const utcDate = new Date(utcDateStr + " UTC");
  if (isNaN(utcDate.getTime())) {
    // Date is invalid, return an empty string
    return '';
  }

  return utcDate.toLocaleString();
}

/**
 * Safely converts a UTC date string to a localized date string.
 * @param utcDateStr - The UTC date string to convert.
 * @param nullMessage - Message to return if the date is null or undefined.
 * @returns Localized date string or a custom message if the input is invalid or null.
 */
export function formatUTCDate(utcDateStr: string | null, nullMessage: string = 'Invalid date'): string {
  if (!utcDateStr) {
    return nullMessage; // Handle null or undefined inputs
  }

  const utcDate = new Date(utcDateStr + " UTC");
  if (isNaN(utcDate.getTime())) {
    return 'Invalid date'; // Handle invalid date formats
  }

  return utcDate.toLocaleDateString(); // Return only the date part
}


export const generalizeValue = (type, value) => {
  if (type === 'datetime-local') {
    const currentDate = new Date(value);
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    const hours = String(currentDate.getUTCHours()).padStart(2, '0');
    const minutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  } else if (type === 'date') {
    const date = new Date(value);
    const normalizedDateStr = '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + '12:00:00'
    const currentDate = new Date(normalizedDateStr);
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    // const hour = String(currentDate.getUTCHours()).padStart(2, '0');
    // const minute = String(currentDate.getUTCMinutes()).padStart(2, '0');
    // const second = String(currentDate.getUTCSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}`
  } else if (type === 'number') {
    if(!value && value !== 0) {
      return null
    }
    return Number(value)
  }

  return value
}

export const convertUTCDateToInputFormat = (utcDateStr) => {
  const utcDate = new Date(utcDateStr ? utcDateStr + " UTC" : new Date());

  const year = utcDate.getFullYear();
  const month = String(utcDate.getMonth() + 1).padStart(2, '0');
  const day = String(utcDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`
}

export const dayAfter = (date) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() + 1);

  return newDate.toISOString().slice(0, 10);
}
