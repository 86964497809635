import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select, { MultiValue, GroupBase } from 'react-select';

const customStyles = {
    control: (provided: any) => ({
        ...provided,
        backgroundColor: '#fff',
        borderColor: '#ced4da',
        borderWidth: 1,
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.1)',
        padding: '5px',
    }),
    menu: (provided: any) => ({
        ...provided,
        zIndex: 9999,
        backgroundColor: '#fff',
        border: '1px solid #ced4da',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#e9ecef' : '#fff',
        color: '#495057',
        padding: '10px',
        cursor: 'pointer',
    }),
    multiValue: (provided: any) => ({
        ...provided,
        backgroundColor: '#e9ecef',
        borderRadius: '4px',
        padding: '2px 5px',
    }),
    multiValueLabel: (provided: any) => ({
        ...provided,
        color: '#495057',
    }),
    multiValueRemove: (provided: any) => ({
        ...provided,
        color: '#495057',
        ':hover': {
            backgroundColor: '#dee2e6',
            color: '#212529',
        },
    }),
};

const ReassignManagerModal = ({
                                  selectedManagerIds,
                                  managers,
                                  isOpen,
                                  toggle,
                                  onSave,
                              }) => {
    const [selectedMulti, setSelectedMulti] = useState<MultiValue<any>>([]);

    const handleMulti = (selectedOptions: MultiValue<any>) => {
        setSelectedMulti(selectedOptions);
    };

    const handleSave = () => {
        const selectedIds = selectedMulti.flatMap(option => {
            const manager = managers.find(manager => manager.id === option.value);
            return manager?.groupIds || []; // Return groupIds if the manager is found, otherwise return an empty array
        });


        onSave(selectedIds);
    };

    useEffect(() => {


        const preSelected = selectedManagerIds.map(id => {


            const manager = managers.find(manager => manager.id === id);

            if (!manager) {
                console.warn(`No manager found for ID: ${id}`);
            }

            return manager ? { value: manager.id, label: manager.name } : null;
        }).filter(Boolean);

        console.log('Preselected Managers:', preSelected);
        setSelectedMulti(preSelected);
    }, [selectedManagerIds, managers, isOpen]);


    const groupedManagers = managers.reduce((groups, manager) => {
        const groupLabel = manager.classification || 'Unclassified';
        const existingGroup = groups.find(group => group.label === groupLabel);

        if (existingGroup) {
            existingGroup.options.push({ value: manager.id, label: manager.name });
        } else {
            groups.push({
                label: groupLabel,
                options: [{ value: manager.id, label: manager.name }],
            });
        }

        return groups;
    }, []);

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <div className="modal-title mt-0 h5" id="inviteModalLabel">
                    Manager Assignment
                </div>
            </ModalHeader>
            <ModalBody>
                <div className="mb-3">
                    <label className="control-label">Managers</label>
                    <Select
                        isMulti
                        value={selectedMulti}
                        onChange={handleMulti}
                        options={groupedManagers}
                        styles={customStyles}
                        className="select2-selection form-control"
                    />
                </div>
                <p>You can reassign this Client to one or more Managers.</p>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggle}
                    className="btn btn-secondary"
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                >
                    Save
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default ReassignManagerModal;
