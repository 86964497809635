
// Auth
import Login from "pages/Authentication/login";
import Logout from "pages/Authentication/Logout";
import UserProfile from "pages/Authentication/user-profile";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import SignUp from "pages/Authentication/Register"
import DeeplinkPage from "pages/Deeplink/DeeplinkPage";

import Calendar from "../pages/Calendar/index"
import InvitationsPage from "../pages/User Management/Invitations/InvitationsPage";
import ManagersPage from "../pages/User Management/Managers/ManagersPage";
import ClientsPage from "../pages/User Management/Clients/ClientsPage";
import CorporatePage from "../pages/User Management/Corporate/CorporatePage";
import AdminsPage from "../pages/User Management/Administrators/AdminPage";
import LocationsPage from "../pages/Location Management/Locations/LocationsPage";
import ResourceCategoriesPage from "../pages/Content Management/Resources/ResourceCategoriesPage";
import ResourcesPage from "../pages/Content Management/Resources/ResourcesPage";
import KnowledgeBasePage from "../pages/Chatbot Management/Knowledge Base/KnowledgeBasePage";
import GoalsPage from "../pages/Content Management/Goals/GoalsPage";
import GoalStepsPage from "../pages/Content Management/Goals/GoalStepsPage";
import GoalStepActivitiesPage from "pages/Content Management/Goals/GoalStepActivitiesPage";
import QuotesPage from "../pages/Content Management/Quotes/QuotesPage";
import GettingStartedPage from "../pages/Content Management/GetttingStarted/GettingStartedPage";
import AnouncementsPage from "../pages/Notification Management/Anouncements/AnouncementsPage";
import NotificationsPage from "../pages/Notification Management/Notifications/NotificationsPage";
import StandingsPage from "../pages/Rewards Management/Campaign Standings/StandingsPage";
import RulesPage from "../pages/Rewards Management/Rules/RulesPage";
import RuleGroupsPage from "../pages/Rewards Management/Rule Groups/RuleGroupsPage";
import CampaignsPage from "../pages/Rewards Management/Campaigns/CampaignsPage";
import ReportClientPage from "../pages/Reports/Clients/ReportClientPage";
import ReportClientGoalsPage from "../pages/Reports/Clients/ReportClientGoalsPage";
import SendNotificationPage from "pages/Notification Management/Notifications/SendNotificationPage";
import ProgramManagementPage from "pages/Location Management/Programs/ProgramManagementPage";
import StandingViewPage from "pages/Rewards Management/Campaign Standings/StandingViewPage";
import {userManager} from "../utils/UserManager";
import GuardrailPage from "pages/Chatbot Management/Guardrail/GuardrailPage";
import ChatHistoryPage from "pages/Chatbot Management/Chat History/ChatHistoryPage";
import SupportChatPage from "pages/Support/Chat/SupportPage";
type Route = {
  path: string;
  component: JSX.Element;
};

const currentUser = userManager;
const tenantSetting = userManager.getUser()?.tenantDetails?.settings;

const roleManagers = tenantSetting?.['classifications']?.['role_manager'] || [];

const classificationRoutes: Route[] = roleManagers.map((role) => {
  const urlSafeTitle = role.title.toLowerCase().replace(/\s+/g, "-"); // Replace spaces with hyphens
  return {
    path: `/managers/:id`,
    component: <ManagersPage />,
  };
});


const classification = userManager.getUser()?.dbUser?.['classification'];

const roleSpecificRoutes: Route[] = roleManagers
    .filter((role) => role.title.toLowerCase() === classification?.toLowerCase()) // Match classification with role.title
    .flatMap((role) => {
      const routes: Route[] = [];


      if (role.canPush) {
        routes.push(
            { path: "/notifications", component: <NotificationsPage title={'Notifications'} /> },
            { path: "/send-notification", component: <SendNotificationPage title={'Send Notification'} /> }
        );
      }

      return routes;
    });



const authProtectedRoutes = [
  { path: "/", exact: true, component: <CorporatePage title={'Corporate Administrators'} /> },

  { path: "/profile", component: <UserProfile /> },

  { path: "/corporate", component: <CorporatePage title={'Corporate Administrators'} /> },
  { path: "/admins", component: <AdminsPage title={'Regional Administrators'} /> },
  { path: "/invitations", component: <InvitationsPage title={'Invitations'} /> },

  { path: "/locations", component: <LocationsPage title={'Locations'} /> },
  { path: "/programs", component: <ProgramManagementPage title={'Program Management'} /> },

  { path: "/resources", component: <ResourceCategoriesPage title={'Resource Categories'} /> },
  { path: "/resources/:categoryId?", component: <ResourcesPage title={'Resources'} /> },
  { path: "/goals", component: <GoalsPage title={'Goal Categories'} /> },
  { path: "/goals/:goalId?", component: <GoalStepsPage title={'Goal Step'} /> },
  { path: "/goals/:goalId/goal-steps/:goalStepId?", component: <GoalStepActivitiesPage title={'Activities'} /> },
  { path: "/quotes", component: <QuotesPage title={'Daily Quotes'} /> },
  { path: "/videos", component: <GettingStartedPage title={'Getting started videos'} /> },

  { path: "/announcements", component: <AnouncementsPage title={'Announcements'} /> },
  { path: "/notifications", component:<NotificationsPage title={'Notifications'} /> },
  { path: "/send-notification", component: <SendNotificationPage title={'Send Notification'} />},


  { path: "/knowledge-bases", component: <KnowledgeBasePage title={'Knowledge Base'}/> },
  { path: "/guardrails", component: <GuardrailPage title={'Guardrail'} /> },

  ...(process.env.REACT_APP_ENV_SETTING === "production" ? [{ path: "/chat", component: <SupportChatPage/> }] : []),
];
const clientTitle = (!currentUser.isCorporate() && tenantSetting?.['client_title']) ? tenantSetting?.['client_title'] : 'Clients';

const authAdminProtectedRoutes = [
  { path: "/", component: <ClientsPage title={`${clientTitle}`} /> },
    ...classificationRoutes,
  { path: "/profile", component: <UserProfile /> },
  { path: "/clients", component: <ClientsPage title={`${clientTitle}`} /> },
  { path: "/invitations", component: <InvitationsPage title={'Invitations'} /> },

  { path: "/resources", component: <ResourceCategoriesPage title={'Resource Categories'} /> },
  { path: "/resources/:categoryId?", component: <ResourcesPage title={'Resources'} /> },
  { path: "/goals", component: <GoalsPage title={'Goal Categories'} /> },
  { path: "/goals/:goalId?", component: <GoalStepsPage title={'Goal Step'} /> },
  { path: "/goals/:goalId/goal-steps/:goalStepId?", component: <GoalStepActivitiesPage title={'Activities'} /> },

  { path: "/calendar", component:  <Calendar /> },

  { path: "/standings", component: <StandingsPage title={'Campaign Standings'} /> },
  { path: "/standings/:campaignId", component: <StandingViewPage title={'Campaign Standing'} /> },
  { path: "/rules", component:<RulesPage title={'Rules'} /> },
  { path: "/rulegroups", component:<RuleGroupsPage title={'Rule Groups'} /> },
  { path: "/campaigns", component: <CampaignsPage title={'Campaigns'} /> },

  { path: "/announcements", component: <AnouncementsPage title={'Announcements'} /> },
  { path: "/notifications", component:<NotificationsPage title={'Notifications'} /> },
  { path: "/send-notification", component: <SendNotificationPage title={'Send Notification'} />},
  { path: "/reports/case", component: <ReportClientPage title={'Reports'} /> },
  { path: "/videos", component: <GettingStartedPage title={'Getting started videos'} /> },
  ...(process.env.REACT_APP_ENV_SETTING === "production" ? [{ path: "/chat", component: <SupportChatPage/> }] : [])
];

const authManagersProtectedRoutes = [
  { path: "/",exact: true, component: <ClientsPage title={`${clientTitle}`} /> },
  { path: "/profile", component: <UserProfile /> },
  { path: "/clients", component: <ClientsPage title={`${clientTitle}`} /> },
  { path: "/invitations", component: <InvitationsPage title={'Invitations'} /> },
  { path: "/resources", component: <ResourceCategoriesPage title={'Resource Categories'} /> },
  { path: "/resources/:categoryId?", component: <ResourcesPage title={'Resources'} /> },
  { path: "/goals", component: <GoalsPage title={'Goal Categories'} /> },
  { path: "/goals/:goalId?", component: <GoalStepsPage title={'Goal Step'} /> },
  { path: "/goals/:goalId/goal-steps/:goalStepId?", component: <GoalStepActivitiesPage title={'Activities'} /> },

  { path: "/calendar", component:  <Calendar /> },
  { path: "/standings", component: <StandingsPage title={'Campaign Standings'} /> },
  { path: "/standings/:campaignId", component: <StandingViewPage title={'Campaign Standing'} /> },
  { path: "/announcements", component: <AnouncementsPage title={'Announcements'} /> },

  { path: "/reports/case", component: <ReportClientPage title={`${clientTitle} Report`} /> },
  { path: "/reports/goals", component: <ReportClientGoalsPage title={`${clientTitle} Personal Goals`} /> },
  // Conditionally add notification routes
  ...roleSpecificRoutes,
  ...(currentUser.isChatbotEnabled() ? [{ path: "/chat-history", component: <ChatHistoryPage/> }] : []),
  ...(process.env.REACT_APP_ENV_SETTING === "production" ? [{ path: "/chat", component: <SupportChatPage/> }] : [])
];
const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/register", component: <SignUp /> },
  { path: "/mobile", component: <DeeplinkPage /> }
]
export { authProtectedRoutes, authAdminProtectedRoutes, authManagersProtectedRoutes, publicRoutes };
