import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap";
import { generalizeValue } from "utils";
import { userManager } from "../../utils/UserManager";

type IManagerModal = {
    data?: any;
    classification?: string;
    isOpen: boolean;
    isEdit?: boolean;
    onSave?: Function;
    toggle: () => void;
};

const ManagerModal: React.FC<IManagerModal> = ({
                                                   data,
                                                   classification,
                                                   isOpen,
                                                   isEdit,
                                                   onSave,
                                                   toggle,
                                               }) => {
    const [newData, setNewData] = useState<any>({});
    const [loading, setLoading] = useState(false); // Add loading state

    const tenantSetting = userManager.getUser()?.tenantDetails?.settings;
    const roleManagers = tenantSetting?.["classifications"]?.["role_manager"] || []; // Fetch roleManagers dynamically


    const toggleW = () => {
        toggle && toggle();
    };

    const handleSave = async () => {
        setLoading(true); // Set loading to true when save operation starts
        if (onSave) {
            await onSave(newData);
        }
        setLoading(false); // Set loading to false once save operation completes
    };

    const handleFieldInput = (e) => {
        const generalizedValue = generalizeValue(e.target.type, e.target.value);

        setNewData((state) => ({
            ...state,
            [e.target.id]: generalizedValue,
        }));
    };

    const handleClassificationChange = (e) => {
        setNewData((state) => ({
            ...state,
            classification: e.target.value, // Update classification in newData
        }));
    };

    useEffect(() => {
        if (isEdit) {
            setNewData({ ...data });
        } else {
            setNewData({});
        }
    }, [data, isEdit, isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={toggleW}>
            <ModalHeader toggle={toggleW}>
                <div className="modal-title mt-0 h5" id="inviteModalLabel">
                    {isEdit ? `Update ${classification}` : `New ${classification}`}
                </div>
            </ModalHeader>
            <ModalBody style={{ maxHeight: "500px", overflowY: "auto" }}>
                <form className="">
                    <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                            Name
                        </label>
                        <input
                            value={newData?.name || ""}
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Enter name"
                            onChange={handleFieldInput}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="phoneNumber" className="form-label">
                            Phone Number
                        </label>
                        <input
                            value={newData?.phoneNumber || ""}
                            type="text"
                            className="form-control"
                            id="phoneNumber"
                            placeholder="Enter phone number"
                            onChange={handleFieldInput}
                        />
                    </div>
                    {roleManagers.length > 0 && (
                        <div className="mb-3">
                            <label htmlFor="classification" className="form-label">
                                Classification
                            </label>
                            <select
                                id="classification"
                                className="form-select form-control"
                                value={newData?.classification || ""}
                                onChange={handleClassificationChange}
                            >
                                <option value="" disabled>
                                    Select Classification
                                </option>
                                {roleManagers.map((role) => (
                                    <option key={role.title} value={role.title}>
                                        {role.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    <p>{`Please fill out the form to ${isEdit ? "update" : "create new"} ${classification?.toLowerCase()}.`}</p>
                </form>
            </ModalBody>
            <ModalFooter>
                <button
                    type="button"
                    onClick={toggleW}
                    className="btn btn-secondary"
                    data-dismiss="modal"
                >
                    Close
                </button>
                <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSave}
                >
                    {loading ? <Spinner size="sm" /> : isEdit ? `Update ${classification}` : `Create ${classification}`}
                </button>
            </ModalFooter>
        </Modal>
    );
};

export default ManagerModal;
