import React, {useEffect, useMemo, useState} from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {
    deleteClient,
    getClients,
    getManagers,
    getTenants,
    reassignClientToGroup,
    removeClientFromGroup,
    sendNewInvitation,
    updateClient
} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import {UserRoleKeys, userManager} from "../../../utils/UserManager";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import InviteModal from "Components/Modal/InviteModal";
import { convertUTC2Local } from "utils";
import DeleteModal from "Components/Modal/DeleteModal";
import { Link } from "react-router-dom";
import ClientModal from "Components/Modal/ClientModal";
import ReassignManagerModal from "Components/Modal/ReassignManagerModal";
interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}
interface Manager {
    id: number;
    name: string;
    groupIds: number[];
}

interface Client {
    id: number;
    name: string;
    email: string;
    phoneNumber: string;
    dateOfBirth: string;
    role: string;
    groupIds: number[]; // Added to store groupIds for each client
}

const ClientsPage = ({title}) => {
    const [isLoading, setLoading] = useState<boolean>(true);
    const [managersData, setManagersData] = useState<any[]>([]);
    const [managerFilterId, setManagerFilterId] = useState<number>();
    const [clientsData, setClientsData] = useState<any[]>([]);
    const currentUser = userManager;
    const userRole = userManager.getUserRole();
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const [reassignModalIsOpen, setReassignModalIsOpen] = useState(false);
    const [managerIds, setManagerIds] = useState<number[]>([]);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);

    const tenantSetting = userManager.getUser()?.tenantDetails?.settings;
    const currentUserRole = userManager.getUserRole();

    const clientTitle = (!currentUser.isCorporate() && tenantSetting?.['client_title']) ? tenantSetting?.['client_title'] : 'Clients';
    const roleManagers = tenantSetting?.['classifications']?.['role_manager'] || [];
    const userClassification = currentUser.getUser()?.dbUser?.['classification']?.toLowerCase();

    // Determine if the user has the canInvite permission
    const canInvite = roleManagers.some(
        (role) =>
            role.title.toLowerCase() === userClassification && role.canInviteClients
    ) || currentUser.isAdmin() ;
    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleReassignModal() {
        setReassignModalIsOpen(!reassignModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(state => !state);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(state => !state);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleReassignManager = (selectedIds: number[]) => {

        if (selectedIds.length === 0) {
            toast.error("At least one manager must be assigned. Please select a manager.");
            return;
        }

        if (!valueToBeModified) return;

        // Compare selected group IDs with current group IDs
        const currentGroupIds = valueToBeModified.groupIds || [];
        const groupIdsToAdd = selectedIds.filter(id => !currentGroupIds.includes(id)); // Groups to add
        const groupIdsToRemove = currentGroupIds.filter(id => !selectedIds.includes(id)); // Groups to remove

        // Create promises for additions and removals
        const promises: Promise<void>[] = [];

        // Add new groups
        groupIdsToAdd.forEach(groupId => {
            promises.push(
                reassignClientToGroup(groupId, [valueToBeModified.id])
                    .then(() => {
                        toast.success(`The ${valueToBeModified.name} was assigned to group ID ${groupId}.`);
                    })
                    .catch(() => {
                        toast.error(`Failed to assign ${valueToBeModified.name} to group ID ${groupId}.`);
                    })
            );
        });

        // Remove groups
        groupIdsToRemove.forEach(groupId => {
            promises.push(
                removeClientFromGroup(groupId, [valueToBeModified.id])
                    .then(() => {
                        toast.success(`The ${valueToBeModified.name} was removed from group ID ${groupId}.`);
                    })
                    .catch(() => {
                        toast.error(`Failed to remove ${valueToBeModified.name} from group ID ${groupId}.`);
                    })
            );
        });

        // Wait for all promises to complete
        Promise.all(promises)
            .then(() => {
                toast.success("All group changes applied successfully.");
                // Optionally update the client state
                setClientsData(state =>
                    state.map(client =>
                        client.id === valueToBeModified.id
                            ? { ...client, groupIds: selectedIds }
                            : client
                    )
                );
            })
            .catch(() => {
                toast.error("Some operations failed. Please review the assignments.");
            });

        setReassignModalIsOpen(false);
    };





    const handleCreateInvite = (newInvite: any, managerId: number) => {
        const createInvite = async () => {
            try {
                if (userRole === UserRoleKeys.RoleAdmin) {
                    const manager = managersData.find(manager => manager.id === managerId);
                    newInvite['groupId'] = manager.groupIds?.[0]
                }

                const response = await sendNewInvitation(UserRoleKeys.RoleNone, newInvite); // Add parameters if needed
                if (response.success) {
                    toast.success("New invitation sent.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to send new invite.");
                }
            } catch (error: any) {

                // Check if the error message matches the condition for an existing pending invitation
                if (error.message === "failed to create auth user") {
                    toast.error("There is an existing invitation pending for this email.");
                } else {
                    toast.error("Failed to send new invite.");
                }
            }
        };

        createInvite();
    }

    const handleEditClient = (client: any) => {
        const modifyClient = async () => {
            try {
                const payload = {
                    id: client.id,
                    name: client.name,
                    phoneNumber: client.phoneNumber,
                    dateOfBirth: client.dateOfBirth.split(" ")?.[0]
                }
                const response = await updateClient(payload); // Add parameters if needed
                if (response.success) {

                    setClientsData((state) => {
                        const findIdx = state.findIndex(item => item?.id === client.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = response.data
                        }

                        return [...state]
                    });
                    toggleEditModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update client.");
                }
            } catch (error) {

                toast.error("Failed to update client.");
            }
        };

        modifyClient();
    }

    const handleDeleteClient = () => {
        const removeClient = async () => {
            try {
                const response = await deleteClient(valueToBeModified.id); // Add parameters if needed
                if (response.success) {
                    toast.success('Client deleted');
                    setClientsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);

                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove client.");
                }
            } catch (error) {

                toast.error("Failed to remove client.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeClient()
        }
    }
    const fetchClients = async () => {
        try {

            // Ensure currentUser and dbUser are defined and have a groupIds array
            const user = currentUser.getUser();
            const firstGroupId = user && user.dbUser && Array.isArray(user.dbUser.groupIds) && user.dbUser.groupIds.length > 0 ? user.dbUser.groupIds[0] : null;

            const filteredGroupId = managersData.find(item => item.id === managerFilterId)?.groupIds?.[0];

            const response = await getClients(1, 0, 20, currentUser.isManager() ? firstGroupId : filteredGroupId); // Add parameters if needed
            if (response.success) {

                setClientsData(response.data.clients);
            } else {
                // Display error notification
                toast.error(response.error || "Failed to fetch clients.");
            }
        } catch (error) {
            toast.error("Failed to fetch clients.");
        }
    };
    const extractManagerIdsForClientGroups = (): number[] => {
        if (!valueToBeModified?.groupIds) return [];
        const matchedManagers = managersData.filter(manager =>
            manager.groupIds.some(groupId => valueToBeModified.groupIds.includes(groupId))
        );
        return matchedManagers.map(manager => manager.id);
    };
    useEffect(() => {
        fetchClients();
    }, [userRole, managerFilterId]);

    useEffect(() => {
        if (userRole === UserRoleKeys.RoleAdmin) {
            const fetchManagers = async () => {
                try {
                    const response = await getManagers(1); // Add parameters if needed
                    if (response.success) {
                        setManagersData(response.data.managers);
                        setManagerFilterId(response.data.managers?.[0]?.id);
                    } else {
                        // Display error notification
                        toast.error(response.error || "Failed to fetch managers.");
                    }
                } catch (error) {

                    toast.error("Failed to fetch managers.");
                }
            };
            fetchManagers();
        }
    }, [userRole]);
    useEffect(() => {
        if (valueToBeModified) {
            const matchingManagerIds = extractManagerIdsForClientGroups();
            setManagerIds(matchingManagerIds);
        }
    }, [valueToBeModified]);
    const customLeftTools = useMemo(() => {
        if (userRole === UserRoleKeys.RoleAdmin) {
            // Define the type for grouped managers
            const groupedManagers: Record<string, Manager[]> = managersData.reduce((groups, manager) => {
                const classification = manager.classification || 'Unclassified'; // Use classification or fallback to 'Unclassified'
                if (!groups[classification]) {
                    groups[classification] = [];
                }
                groups[classification].push(manager);
                return groups;
            }, {} as Record<string, Manager[]>);

            return (
                <React.Fragment>
                    <select
                        className="form-select form-control"
                        value={managerFilterId || managersData[0]?.id || 'Select'} // Ensure the default selection uses the first manager's ID
                        onChange={(e) => {
                            const selectedManagerId = Number(e.target.value);
                            setManagerFilterId(selectedManagerId); // Set managerFilterId to the selected manager's ID
                        }}
                    >
                        <option value="Select" disabled={!managersData.length}>Select</option>
                        {Object.entries(groupedManagers).map(([classification, managers]) => (
                            <optgroup key={classification} label={classification}>
                                {managers.map(manager => (
                                    <option key={manager.id} value={manager.id}>
                                        {manager.name}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                    </select>
                </React.Fragment>
            );
        } else {
            return <React.Fragment />;
        }
    }, [managerFilterId, managersData, userRole]);



    const columns: column[] = useMemo(
        () => [
            {
                header: 'ID',
                accessorKey: 'id',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Email',
                accessorKey: 'email',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Name',
                accessorKey: 'name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Role',
                accessorKey: 'role',
                cell: (info) => <RoleCell value={info.getValue()} />,
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Phone Number',
                accessorKey: 'phoneNumber',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Date of Birth',
                accessorKey: 'dateOfBirth',
                cell: (info) => convertUTC2Local(info.getValue()),
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Action',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            <Link to="#" className="text-success" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleEditModal()
                            }}>
                            <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                            <UncontrolledTooltip placement="top" target="editTooltip">
                                Edit
                            </UncontrolledTooltip>
                            </Link>
                            {userRole !== UserRoleKeys.RoleManager &&
                                <Link to="#" className="text-success" onClick={() => {
                                    setValueToBeModified(cellProps.row.original)
                                    toggleReassignModal()
                                }}>
                                    <i className="mdi mdi-redo font-size-18" id="reassignTooltip" />
                                    <UncontrolledTooltip placement="top" target="reassignTooltip">
                                        Manager Assignment
                                    </UncontrolledTooltip>
                                </Link>
                            }
                            <Link to="#" className="text-danger" onClick={() => {
                                setValueToBeModified(cellProps.row.original)
                                toggleDeleteModal()
                            }}>
                            <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                            <UncontrolledTooltip placement="top" target="deleteTooltip">
                                Delete
                            </UncontrolledTooltip>
                            </Link>
                        </div>
                    )
                }
            }
            // Add more columns as needed
        ],
        [userRole]
    );



    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                        {isLoading ? <Spinners setLoading={setLoading} /> :
                            <Row>
                                <Col lg={12}>
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={clientsData || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                isAddButton={
                                                    currentUserRole === UserRoleKeys.RoleAdmin ||
                                                    currentUserRole === UserRoleKeys.RoleCorp
                                                        ? true
                                                        : canInvite ?? false // Default to false if setting is undefined
                                                }
                                                isCustomPageSize={true}
                                                SearchPlaceholder={`Search ${clientTitle.toLowerCase()}...`}
                                                handleUserClick={toggleNewModal}
                                                pagination="pagination"
                                                buttonClass="btn btn-success btn-rounded"
                                                buttonName={`Invite New ${clientTitle}`}
                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                theadClass="table-light"
                                                customLeftTools={
                                                    customLeftTools
                                                }
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        }
                            {canInvite && (  <InviteModal showManagersDropdown={userRole === UserRoleKeys.RoleAdmin} retrievePhone={true} enableInviteToggle={true} managers={managersData} isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateInvite} />)}
                        <ClientModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditClient} />
                        <ReassignManagerModal selectedManagerIds={managerFilterId ? managerIds : []}  isOpen={reassignModalIsOpen} managers={managersData} toggle={toggleReassignModal} onSave={handleReassignManager} />
                        <DeleteModal
                            isOpen={deleteModalIsOpen}
                            toggle={toggleDeleteModal}
                            message={<p>Are you sure you want to delete? This action cannot be undone.</p>}
                            onConfirmDelete={handleDeleteClient}
                        />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default ClientsPage;
